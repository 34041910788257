/* Header (AppBar) Styling */
.appbar {
  background-color: rgba(0, 0, 0, 0.8) !important; /* Black background with slight transparency */
  backdrop-filter: blur(5px); /* Slight blur effect for depth */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); /* Slightly darker shadow for contrast */
  position: sticky; /* Sticky positioning */
  top: 0; /* Stick to the top of the screen */
  width: 100%; /* Full width */
  z-index: 10; /* Ensure header stays above the slider */
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

/* Toolbar (Header Contents) Styling */
.toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Distribute space between logo, nav-links, and hamburger */
  
}

.logo {
  height: 50px; /* Reduced logo height */
  width: auto; /* Maintain aspect ratio */
  max-width: 100%;
}

/* Active and Inactive Link Styling */
a[isactive='1'], a[isactive='0'] {
  padding: 6px 12px; /* Smaller padding for a compact look */
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-family: 'Raleway' !important;
}

a[isactive='1'] {
  color: #FF0000 !important; /* Red color for active link */
  font-weight: bold !important;
  background-color: rgba(255, 0, 0, 0.1); /* Slight red overlay on active link */
}

a[isactive='0'] {
  color: white !important; /* Slightly transparent white for inactive links */
  font-weight: bold !important;

}

/* Navigation Links Styling */
.nav-links {
  display: flex;
  align-items: center;
  gap: 15px; /* Reduced gap between links */
}

/* Hamburger Menu - Hidden on Desktop */
.hamburger-menu {
  visibility: hidden; /* Make it invisible by default */
  position: absolute; /* Keep it out of the flow, off the visible layout */
}

/* Responsive Menu and Drawer */
@media (max-width: 600px) {
  .nav-links {
    display: none; /* Hide the navigation links on mobile */
  }

  .hamburger-menu {
    visibility: visible; /* Make it visible on mobile screens */
    position: relative; /* Re-position it correctly in the layout */
    display: block ; /* Show the hamburger menu on mobile */
    color: #FF0000; /* Red color for hamburger icon */
    background: transparent; /* Remove background color if unwanted */
  }

  .drawer-list {
    background-color: white; /* White background for the drawer */
    height: 100%;
    width: 250px; /* Set a fixed width for the drawer */
  }

  .drawer-link {
    color: #000000 !important; /* Set the drawer link text to black */
    text-decoration: none;
    padding: 10px 20px;
    display: block;
    transition: background-color 0.3s ease;
  }

  .drawer-active-link {
    color: #FF0000 !important; /* Red color for active drawer link */
    font-weight: bold;
  }

  .drawer-link:hover,
  .drawer-active-link:hover {
    color: #FF0000; /* Red color on hover */
    background-color: rgba(255, 0, 0, 0.1); /* Light red overlay on hover */
  }

  /* Adjust logo size for mobile */
  .logo {
    height: 30px; /* Slightly smaller logo size on mobile */
  }
}

