/* General Styling */
.careers-page {
    font-family: Arial, sans-serif;
    color: #333;
  }
  
  /* Hero Section */
  .careers-hero {
    background: linear-gradient(135deg, #1a2a6c, #b21f1f, #fdbb2d);
    padding: 60px 20px;
    text-align: center;
    color: white;
    animation: fadeIn 1.5s ease-in-out;
  }
  
  .careers-hero h1 {
    font-size: 3rem;
    margin-bottom: 20px;
  }
  
  .careers-hero p {
    font-size: 1.2rem;
  }
  
  .cta-button {
    padding: 12px 24px;
    background-color: #0073e6;
    color: #fff;
    border-radius: 20px;
    text-decoration: none;
    transition: background 0.3s;
    margin-top: 20px;
  }
  
  .cta-button:hover {
    background-color: #005bb5;
  }
  
  /* Why Join Us Section */
  .why-join-us {
    padding: 50px 20px;
    text-align: center;
  }
  
  .why-join-us h2 {
    color: #1a2a6c;
    font-size: 2.5rem;
    margin-bottom: 30px;
  }
  
  .benefits {
    display: flex;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
  }
  
  .benefit-item {
    background: #fff;
    border-radius: 10px;
    padding: 30px;
    width: 250px;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .benefit-item:hover {
    transform: translateY(-10px);
    box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.15);
  }
  
  .benefit-item h3 {
    color: #333;
    margin-top: 10px;
    font-size: 1.4rem;
  }
  
  .benefit-item p {
    color: #666;
    margin-top: 10px;
  }
  
  /* Job Listings */
  .job-listings {
    padding: 50px 20px;
    background-color: #f7f7f7;
    text-align: center;
  }
  
  .job-listings h2 {
    font-size: 2.5rem;
    color: #1a2a6c;
    margin-bottom: 30px;
  }
  
  .job-listing {
    background: white;
    padding: 20px;
    margin: 15px auto;
    max-width: 500px;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .job-listing:hover {
    transform: scale(1.02);
  }
  
  .application-info {
    margin-top: 20px;
    text-align: center;
    font-size: 1.1rem;
    color: #333;
  }
  
  .application-info a {
    color: #0077b5; /* Optional color for the email link */
    font-weight: bold;
    text-decoration: none;
  }
  
  .application-info a:hover {
    text-decoration: underline;
  }
  
  /* FAQ Section */
  .faq-section {
    padding: 50px 20px;
    background-color: #fff;
    text-align: center;
  }
  
  .faq-section h2 {
    font-size: 2.5rem;
    color: #1a2a6c;
    margin-bottom: 30px;
  }
  
  .faq-item {
    background-color: #f9f9f9;
    padding: 10px;
    margin: 10px auto;
    max-width: 800px;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .faq-item:hover {
    background-color: #e8f4fc;
  }
  
  .faq-item h3 {
    font-size: 1.5rem;
    color: #1a2a6c;
  }
  
  .faq-item p {
    display: none;
    font-size: 1rem;
    color: #333;
    margin-top: 10px;
  }
  
  .faq-item:hover p {
    display: block;
  }
  
  /* Animations */
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  /* Responsive Styling */
  @media (max-width: 768px) {
    .benefits {
      flex-direction: column;
    }
  
    .job-listing {
      width: 90%;
    }
  
    .faq-item {
      width: 90%;
    }
  }

  /* Responsive Styling */
@media (max-width: 768px) {
    .benefit-item {
      width: 100%;
      margin-bottom: 20px;
    }
  
    .why-join-us h2 {
      font-size: 2rem;
    }
  }
  
  @media (max-width: 480px) {
    .why-join-us {
      padding: 30px 10px;
    }
  
    .why-join-us h2 {
      font-size: 1.8rem;
    }
  }
  
  
  
  