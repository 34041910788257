/* Add this to your existing CSS code */

/* Make the page responsive */
.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  /* Remove horizontal scrollbar */
}

/* Make the slider responsive */
.slider-section {
  position: relative;
  width: 100%;
  overflow: hidden;
  /* Prevents any overflow */
  background-color: white;
}

.slider-image {
  width: 100%;
  height: 90vh;
  /* Full viewport height */
  object-fit: cover;
  /* Cover the entire area while maintaining aspect ratio */
  transition-property: 50%;
}


/* Make the text container responsive */
.slider-text-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* Center the text */
  background-color: rgba(201, 195, 195, 0.774);
  /* Background highlight with transparency */
  padding: 20px;
  text-align: center;
  /* Center text */
  z-index: 2;
  /* Ensure content is above the image */
  width: 100%;
  /* Make sure the text container fits the slide */
  max-width: 700px;
  /* Optional: Limit the maximum width for readability */
  opacity: 0.8;
  border-radius: 20px;
}

.hero-title {
  color: red !important;
  font-size: 2.5rem !important;
  font-weight: bold !important;
  font-family: 'Poppins' !important;
}

.hero-subtitle {
  color: black !important;
  margin-top: -15px;
  font-family: 'Raleway' !important;
}

.hero-button {
  background-color: red !important;
  color: white !important;
}

/* Featured Section */

.features-section {
  padding: 60px 20px;
  background-color: white;
  color: black;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center !important;
  /* Center the section's content */
}

.features-title {
  margin-bottom: 40px !important;
  text-align: center;
  color: red !important;
  font-size: 2.5rem !important;
  font-weight: bold !important;
  font-family: 'Poppins' !important;
}

.feature-cards-container {

  display: flex;
  justify-content: center;
  /* Center the cards horizontally */
  flex-wrap: wrap;
  /* Ensure cards wrap on smaller screens */
  width: 100%;
}

.feature-card {
  padding: 15px;
  /* Reduced padding inside the card */
  border-radius: 10px;
  border: 1px solid #ccc;
  transition: transform 0.3s, box-shadow 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 300px;
  /* Adjusted height for uniformity */
  width: 100%;
  /* Set a fixed width */
  text-align: center;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  /* Ensure content doesn't overflow */
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.feature-icon {
  width: 100px !important;
  /* Adjusted icon width */
  height: 100px !important;
  /* Adjusted icon height */
  margin-bottom: 1rem;
}

.feature-title {
  font-size: 1.7rem;
  font-weight: bold;
  color: red;
  margin-bottom: 1rem !important;
}

.feature-description {
  font-size: 1rem;
  color: black;
  line-height: 1.4;
  font-family: 'Raleway' !important;
}

@media (max-width: 768px) {
  .feature-card {
    height: auto;
    /* Auto height for mobile responsiveness */
  }

  .feature-icon {
    width: 80px !important;
    /* Smaller icon size for mobile */
    height: 80px !important;
  }
}

.company-logos-section {
  padding: 40px 20px;
  background-color: #ffffff;
  width: 100%;
}

.logos-title {
  text-align: center;
  color: red !important;
  font-size: 2.5rem !important;
  font-weight: bold !important;
  margin-bottom: 20px;
  font-family: 'Poppins' !important;
}

.logos-slider {
  display: flex;
  overflow: hidden;
}

.logo-slide {
  flex: 0 0 auto;
  margin: 0 10px;
}

/* Shape the logos and center them */
.logo-shape {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  /* Circular shape */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.company-logo {
  max-width: 80%;
  max-height: 80%;
  object-fit: contain;
  /* Ensures the logo fits within the shape */
}

/* Media queries for responsiveness */

/* For screens smaller than 768px (tablets and mobiles) */
@media only screen and (max-width: 767px) {
  .company-logos-section {
    padding: 20px 10px;
  }

  .logos-title {
    font-size: 1.8rem;
  }

  .logo-shape {
    width: 100px;
    height: 100px;
  }

  .company-logo {
    max-width: 80% !important;
    max-height: 80% !important;
  }
}

/* For screens smaller than 480px (mobiles) */
@media only screen and (max-width: 479px) {
  .company-logos-section {
    padding: 10px 5px;
  }

  .logos-title {
    font-size: 1.2rem;
  }

  .logo-shape {
    width: 60px;
    height: 60px;
  }

  .company-logo {
    max-width: 70% !important;
    max-height: 70% !important;
  }
}

/* Make the about us preview responsive */
.about-preview-section {
  padding: 60px 20px;
  background-color: white;
  width: 100%;
}

.about-title {
  font-family: 'Poppins' !important;
  text-align: center;
  color: red !important;
  font-size: 2.5rem !important;
  font-weight: bold !important;
  color: red !important;
  /* Change title color to red */
}

.about-description {
  text-align: center;
  /* Center the description */
  font-size: 1.2rem;
  max-width: 800px;
  color: black;
  margin: 0 auto;
  font-family: 'Raleway' !important;
  /* Center the text block */
}

/* Make the testimonials section responsive */
.testimonials-section {
  padding: 30px 20px;
  background-color: #ffffff;
  /* White background for contrast */
  width: 100%;
  overflow-x: hidden;
  /* Remove horizontal scrollbar */

}

.testimonials-title {
  text-align: center;
  color: red !important;
  font-size: 2.5rem !important;
  font-weight: bold !important;
  margin-bottom: 40px !important;
  color: red !important;
  font-family: 'Poppins' !important;
}

.testimonial-card {
  padding: 20px;
  border-radius: 10px;
  background-color: #f5f5f5 !important;
  transition: transform 0.3s;
  color: black !important;
  display: flex;
  /* Enable flex layout for uniformity */
  flex-direction: column;
  /* Arrange items vertically */
  height: 350px;
  /* Fixed height for uniformity */
}

.testimonial-card:hover {
  transform: translateY(-5px);
}

.testimonial-content {
  justify-content: center;
}


.testimonial-feedback {
  font-style: italic;
  margin-bottom: 10px;
  flex-grow: 1;
  /* Allow feedback to grow */
}

.testimonial-name {
  font-weight: bold !important;
  margin-top: 20px !important;
}

.testimonial-company {
  color: gray;
}

.trusted-by {
  text-align: center;
  padding: 2rem 0;

}

.trusted-heading {
   color: red !important;
  font-size: 2.5rem !important;
  font-weight: bold !important;
  margin-bottom: 1.5rem;
  font-family: 'Poppins' !important;
}

.trusted-logos {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12rem;
  /* Space between logos */
}

.Tlogo {
  max-width: 200px;
  /* Adjust the size as needed */
  height: auto;
  transition: transform 0.3s ease;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .trusted-logos {
    gap: 8rem;
    /* Reduce gap between logos on medium screens */
  }

  .trusted-heading {
    font-size: 1.8rem;
  }

  .Tlogo {
    max-width: 170px;
  }
}

@media (max-width: 768px) {
  .trusted-logos {
    gap: 3rem;
    /* Stack logos and reduce gap on smaller screens */
  }

  .trusted-heading {
    font-size: 1.6rem;
  }

  .Tlogo {
    max-width: 140px;
  }
}

@media (max-width: 480px) {
  .trusted-logos {
    gap: 2rem;
    /* Reduce gap further on mobile screens */
  }

  .trusted-heading {
    font-size: 1.4rem;
  }

  .Tlogo {
    max-width: 100px;
    /* Smaller size for mobile screens */
  }
}




/* Media queries for responsiveness */

/* For screens smaller than 768px (tablets and below) */
@media (max-width: 768px) {
  .testimonial-card {
    height: auto;
    /* Remove fixed height */
    flex-direction: row;
    /* Arrange items horizontally */
  }

  .testimonial-avatar {
    width: 80px !important;
    height: 80px !important;
  }

  .testimonial-content {
    text-align: left;
    /* Left-align content on smaller screens */
  }
}

/* For screens smaller than 480px (mobile devices) */
@media (max-width: 480px) {
  .testimonial-card {
    flex-direction: column;
    /* Arrange items vertically again */
  }

  .testimonial-avatar {
    width: 60px !important;
    height: 60px !important;
  }

  .testimonial-content {
    text-align: center;
    /* Center-align content on mobile devices */
  }
}