.footer-container {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 40px 0;
}

.footer-grid {
  border-top: 1px solid #333333;
  display: flex;
  flex-wrap: wrap;
}

.footer-logo img {
  width: 150px;
  height: auto;
  margin-bottom: 15px;
}

.footer-title {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: red !important;
  font-weight: bold !important;
}

.footer-links,
.footer-socials,
.footer-contact {
  display: flex;
  flex-direction: column;
}

.footer-link {
  text-decoration: none;
  margin-bottom: 10px;
  transition: color 0.3s;
}

.footer-link:hover {
  color: #ffcc00; /* Added a hover color for better visibility */
}

.footer-socials {
  flex-direction: row;
}

.social-icon {
  color: white;
  margin-right: 10px;
  transition: color 0.3s, transform 0.3s;
}

.social-icon:hover {
  color: #ffcc00;
  transform: scale(1.1);
}

.footer-contact {
  margin-top: 10px;
}

.contact-item {
  margin-bottom: 10px;
}

.footer-bottom {
  margin-top: 40px;
  border-top: 1px solid #ffffff;
  padding-top: 20px;
  text-align: center;
  color: #ffffff;
  font-size: 0.9rem;
}

/* Responsive Styling */
@media (max-width: 768px) {
  .footer-container {
    padding: 30px 0;
  }

  .footer-title {
    font-size: 1.3rem;
  }

  .footer-logo img {
    width: 120px;
  }

  .social-icon {
    margin-right: 8px;
  }
}

@media (max-width: 480px) {
  .footer-container {
    padding: 20px 0;
  }

  .footer-title {
    font-size: 1.2rem;
  }

  .footer-link {
    font-size: 0.9rem;
  }

  .contact-item {
    font-size: 0.9rem;
  }

  .footer-logo img {
    width: 100px;
  }

  .social-icon {
    margin-right: 5px;
  }
}
