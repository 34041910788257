/* Base Styling */
body {
  font-family: 'Arial', sans-serif;
  background-color: white !important;
  margin: 0;
  padding: 0;
  color: #333;
}

.blog-container {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.blog-header {
  text-align: center;
}

.blog-title {
  font-size: 2.5rem;
  color: red;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 1rem;
  font-family: 'Poppins' !important;
}

.blog-subtitle {
  font-size: 1.2rem;
  color: black;
  font-weight: 300;
}

.blog-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 2rem;
}

.blog-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 100%;
  max-width: 300px; /* Restricts max width for large screens */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  text-align: center;
  height: 320px;
}

.blog-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.blog-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 5px;
}

.blog-card-title {
  font-size: 1.6rem;
  color: black !important;
  margin: 0.4rem 0 0.3rem;
  font-weight: bold;
}

.read-more-button {
  background-color: white;
  color: black;
  text-transform: uppercase;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
  margin-top: auto;
}

.read-more-button:hover {
  background-color: red;
  color: white;
}

/* Modal Styling */
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 2rem;
  border-radius: 10px;
  width: 80%;
  max-width: 600px;
  position: relative;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.3s ease;
  overflow-y: auto; /* Enables internal scrolling */
  max-height: 80vh; /* Constrains height to be within the viewport */
  font-family: 'Raleway' !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  background: transparent;
  border: none;
  font-size: 2rem;
  color: #333;
  cursor: pointer;
  z-index: 1001; /* Ensure it's above modal content */
}

.close-button:hover {
  color: #007BFF;
}

.modal h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: blue !important;
}

.modal p {
  font-size: 1rem;
  line-height: 1.6;
  color: black;
}

/* Responsive Design */
@media (max-width: 768px) {
  .blog-title {
    font-size: 2rem;
  }

  .blog-subtitle {
    font-size: 1rem;
  }

  .blog-cards {
    flex-direction: column;
    align-items: center;
  }

  .blog-card {
    width: 90%;
    height: auto;
  }

  .modal-content {
    width: 90%;
    padding: 1.5rem;
  }
}

@media (max-width: 480px) {
  .blog-title {
    font-size: 1.5rem;
  }

  .blog-subtitle {
    font-size: 0.9rem;
  }

  .blog-card {
    width: 100%;
    height: auto;
  }

  .blog-card-title {
    font-size: 1.3rem;
  }

  .modal-content {
    padding: 1rem;
  }

  .close-button {
    top: 5px;
    right: 10px;
    font-size: 1.5rem; /* Slightly smaller button size for mobile */
  }

  .modal h2 {
    font-size: 1.5rem;
  }
}
