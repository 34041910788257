/* src/components/About.css */

/* General Styling */
.about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-x: hidden; /* Prevent horizontal scroll */
  font-family: 'Roboto', sans-serif; /* Ensure consistent font */
}

/* Hero Section */
.about-hero-section {
  position: relative;
  width: 100%;
  height: 60vh;
  background: url('../assets/images/About/Background.JPG') no-repeat center center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.about-hero-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.about-hero-section > .MuiContainer-root {
  position: absolute;
  z-index: 2; /* Ensure content is above the overlay */
  transform: translate(-50%, -50%); /* Center the text */
  background-color: rgba(201, 195, 195, 0.315);  /* Background highlight with transparency */
  top: 50%;
  left: 50%;
  margin-top: 50px;
}

.about-hero-title {
  font-size: 3rem !important;
  font-weight: bold;
  color: white;
  margin-top: 25px !important;
  font-weight: 700 !important; /* Bold weight for increased visual width */
  letter-spacing: 1.0px; /* Adds space between letters */
  font-stretch: expanded !important; /* Stretches the font, if supported */
  text-transform: uppercase !important;
  font-family: 'Poppins' !important;
}

.about-hero-subtitle {
  font-size: 1.5rem;
  color: white;
  font-weight: bold !important;
  font-family: 'Raleway' !important;
}

/* Company History Section */
.about-history-section {
  padding: 60px 20px;
  background-color: #ffffff;
  width: 100%;
}

.about-section-title {
  text-align: center;
  color: red !important;
  font-size: 2.5rem !important;
  font-weight: bold !important;
  margin-bottom: 40px !important;
  font-family: 'Poppins' !important;
}

.about-section-content {
  font-size: 1.1rem;
  color: black !important;
  line-height: 1.6;
  max-width: 100%;
  text-align: center;
  font-family: 'Raleway' !important;
}

/* Mission, Vision, and Goals Section */
.about-mvg-section {
  padding: 30px 20px;
  background-color: white;
  width: 100%;
}

.mvg-card {
  padding: 30px;
  border-radius: 10px;
  background-color: #ffffff !important;
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100%; /* Ensure all cards stretch to the same height */
  box-sizing: border-box;
  transition: transform 0.3s, box-shadow 0.3s;
}

.mvg-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.mvg-title {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: black;
  font-weight: bold !important;
  font-family: 'Poppins' !important;
}

.mvg-content {
  font-size: 1rem;
  color: #666666;
  line-height: 1.5;
  flex-grow: 1; /* Pushes content to fill the card */
  font-family: 'Raleway' !important;
}

.mvg-card {
  padding: 20px;
  text-align: center;  /* Center content */
  background-color: #f5f5f5;
  border-radius: 10px;
}

.mvg-icon {
  width: 80px;  /* Adjust size as needed */
  height: 80px;
  margin-bottom: 15px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.mvg-title {
  font-weight: bold;
  color: red;
  margin-top: 10px;
  font-family: 'Poppins' !important;
}

.mvg-content {
  font-size: 16px;
  line-height: 1.5;
  color: black;
  font-family: 'Raleway' !important;
}


/* Achievements Section */
.about-achievements-section {
  padding: 60px 20px;
  background-color: white;
  width: 100%;
}

.achievement-card {
  padding: 30px;
  border-radius: 10px;
  background-color: #ffffff !important;
  text-align: center;
  height: 100%;
  box-sizing: border-box;
  transition: transform 0.3s, box-shadow 0.3s;
}

.achievement-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.achievement-title {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: red;
  font-family: 'Poppins' !important;
}

.achievement-content {
  font-size: 1rem;
  color: black;
  line-height: 1.5;
  font-family: 'Raleway' !important;
}

/* Map Section Styles */
.map {
  background-color: white !important;
}

/* Center and style the map section */
.map-section {
  background-color: white !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px; /* Add some padding for better spacing */
}

/* India Map Styling */
.india-map {
  background-color: white !important;
  max-width: 500px; /* Limit the maximum width of the image */
  width: 90%; /* Allow the image to take up full width of container */
  height: auto; /* Maintain aspect ratio */
  display: block; /* Center the image */
  border-radius: 10px; /* Optional rounded corners */
}

/* Responsive Adjustments for Tablets */
@media (max-width: 1024px) {
  .india-map {
    max-width: 700px; /* Reduce the max width for medium-sized screens */
    padding: 0 15px; /* Add padding for small screens */
  }
}

/* Responsive Adjustments for Mobile */
@media (max-width: 768px) {
  .india-map {
    max-width: 600px; /* Reduce the max width for smaller screens */
    padding: 0 10px; /* Add padding for smaller screens */
  }
}

@media (max-width: 480px) {
  .india-map {
    max-width: 100%; /* Full width on mobile */
    padding: 0 5px; /* Reduce padding for mobile screens */
    border-radius: 5px; /* Smaller border radius for mobile */
  }

  .map-section {
    padding: 15px 10px; /* Reduce padding on mobile */
  }
}



/* Call to Action Section */
.about-cta-section {
  padding: 40px 20px; /* Increased padding for a more spacious layout */
  background-color: white;
  width: 100%;
  color: black;
  text-align: center; /* Center the text */
  display: flex; /* Enable flexbox */
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Center children horizontally */
  justify-content: center; /* Center children vertically */
}

.about-cta-title {
  font-size: 2.0rem !important; /* Slightly reduced size for better visibility */
  margin-bottom: 20px;
  color: red;
  font-weight: bold; /* Increased font weight for emphasis */
  text-transform: uppercase; /* Uppercase for a strong impression */
  letter-spacing: 2px; /* Spacing between letters for better readability */
  
}

.about-cta-description {
  font-size: 1.2rem !important;
  margin-bottom: 30px;
}

.about-cta-button {
  background-color: red !important;
  color: white !important;
  padding: 12px 30px;
  font-size: 1rem;
  border-radius: 25px;
  text-decoration: none;
  display: inline-block;
  transition: background-color 0.3s, transform 0.3s;
}

.about-cta-button:hover {
  background-color: #dddddd;
  transform: translateY(-3px);
}

