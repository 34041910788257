/* Gallery Container Styles */
.gallery-container {
  padding: 20px;
  text-align: center;
  background-color: white;
}

.gallery-title {
  font-size: 2.5rem;
  margin-bottom: 15px;
  color: black;
  font-style: italic;
  font-family: 'Poppins' !important;
}

/* Filter Button Styles */
.gallery-filters {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.filter-btn {
  background-color: #fff;
  border: 1px solid black;
  padding: 10px 20px;
  margin: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 1.2rem;
  border-radius: 10px;
}

.filter-btn:hover,
.filter-btn.active {
  background-color: red;
  color: #fff;
  border-color: #1e90ff;
}

/* Gallery Grid */
.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.gallery-item {
  position: relative;
  overflow: hidden; /* Hide overflow to avoid extra content */
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

.gallery-img {
  width: 100%;
  height: auto;
  object-fit: contain; /* Display full image without cutting it */
  display: block;
  transition: transform 0.3s ease;
}

/* Adding a zoom effect on hover */
.gallery-item:hover .gallery-img {
  transform: scale(1.05);
}

/* Overlay to display image category */
.gallery-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  font-size: 1.2rem;
  text-align: center;
  transition: opacity 0.3s ease;
  opacity: 0;
}

.gallery-item:hover .gallery-overlay {
  opacity: 1;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .gallery-title {
    font-size: 2.2rem;
  }

  .filter-btn {
    padding: 8px 16px;
    font-size: 1.1rem;
  }

  .gallery-grid {
    gap: 15px;
  }
}

@media (max-width: 768px) {
  .gallery-title {
    font-size: 2rem;
  }

  .filter-btn {
    padding: 8px 12px;
    font-size: 1rem;
  }

  .gallery-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 12px;
  }
}

@media (max-width: 480px) {
  .gallery-container {
    padding: 10px;
  }

  .gallery-title {
    font-size: 1.6rem;
  }

  .filter-btn {
    padding: 6px 10px;
    font-size: 0.9rem;
    flex: 1 1 45%;
  }

  .gallery-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px;
  }

  .gallery-overlay {
    font-size: 1rem;
  }
}
