/* General Styles */
.services-container {
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.services-header {
  text-align: center;
  margin-bottom: 40px;
  background-color: #fff;
  padding: 20px;
}

.services-title {
  color: red !important;
  font-size: 2.5rem !important;
  font-weight: bold !important;
  margin-bottom: 10px;
  font-weight: 700;
  font-weight: bold;
  font-family: 'Poppins' !important;
}

.services-quote {
  font-size: 1.5rem;
  color: black;
  font-style: italic;
  margin-top: 10px;
  font-weight: 400 !important;
}

/* Grid Layout for Services */
.services-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three equal columns */
  gap: 20px;
  width: 100%;
  max-width: 1200px; /* Limit grid width for larger screens */
  box-sizing: border-box;
}

/* Individual Service Card */
.service-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
  padding: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
}

/* Service Card Image */
.service-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px 8px 0 0;
}

/* Service Title and Description */
.service-title {
  font-size: 2rem !important;
  color: red !important;
  margin-top: 15px;
  font-family: 'Poppins' !important;
}

.service-description {
  color: black !important;
  font-size: 1rem;
  margin-top: 10px;
  line-height: 1.5;
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
  .services-grid {
    grid-template-columns: repeat(2, 1fr); /* Two columns on medium screens */
  }
}

@media (max-width: 768px) {
  .services-grid {
    grid-template-columns: 1fr; /* One column on small screens */
  }
}
