/* Contact.css */

.contact-container {
  background-color: white; /* Full white background */
  color: red; /* Black text */
  padding: 40px;
  text-align: center;
  font-family: 'Poppins' !important;
}

h2 {
  color: red !important;
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px; /* Added margin for spacing */
  font-family: 'Poppins' !important;
}

.contact-info {
  display: flex;
  justify-content: center; /* Horizontally centers the cards */
  align-items: center; /* Vertically centers the cards if required */
  gap: 20px; /* Adds space between the cards */
  flex-wrap: wrap; /* Allows wrapping if screen width is small */
}

.info-card {
  background-color: #f5f5f5; /* Card background color */
  padding: 20px;
  color: black !important;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 250px; /* Width for each card */
}


.info-card:hover {
  transform: scale(1.05); /* Slight hover effect */
}

.info-card h2 {
  margin-bottom: 15px;
  font-size: 1.5rem;
  color: black; /* Keep heading color black */
  font-family: 'Poppins' !important;
}

.contact-form {
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  padding: 20px; /* Add padding for better spacing */
  background-color: #fff; /* Ensure form background is white */
}

.contact-form input,
.contact-form textarea {
  width: 80%;
  padding: 15px;
  margin: 10px 0;
  border: 2px solid #ccc; /* Soft border color */
  border-radius: 8px;
  background-color: white; /* Input background */
  color: black; /* Black text for inputs */
  transition: border-color 0.3s;
}

.contact-form input:focus,
.contact-form textarea:focus {
  outline: none;
  border-color: #007BFF; /* Blue border on focus */
}

.contact-form button {
  background-color: #ccc; /* Button color */
  color: black; /* Button text color */
  padding: 12px 24px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1.1rem;
  transition: background-color 0.3s, transform 0.2s;
}

.contact-form button:hover {
  color: #ccc;
  background-color: red;
  transform: translateY(-2px); /* Lift effect */
}

.social-media {
  margin: 40px 0;
}

.social-media h3 {
  font-size: 2rem;
  color: red; /* Heading color */
  font-weight: 500px !important;
  font-weight: bold;
}

.social-media a {
  color: black; /* Link color */
  margin: 0 15px;
  font-size: 1.5rem; /* Increased icon size */
  transition: color 0.3s;
  text-decoration: none;
}

.social-media a:hover {
  color: blue; /* Change color on hover */
}

.map h3{
  font-weight: 500px !important;
  font-size: 2rem;
}

.map {
  margin: 40px 0;
}

iframe {
  border-radius: 8px;
  border: 2px solid #ccc; /* Soft border color */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

/* Media Queries */
@media (max-width: 768px) {
  h1 {
    font-size: 2rem; /* Smaller heading on mobile */
  }

  .contact-info {
    flex-direction: column; /* Stack cards on small screens */
    align-items: center; /* Center cards */
  }

  .info-card {
    width: 90%; /* Full width on mobile */
    margin: 10px 0; /* Space between stacked cards */
  }

  .contact-form input,
  .contact-form textarea {
    width: 90%; /* Full width for inputs on mobile */
  }

  .contact-form button {
    width: 90%; /* Full width for button on mobile */
  }

  .social-media h3 {
    font-size: 1.2rem; /* Smaller social media heading */
  }

  .social-media a {
    font-size: 1.2rem; /* Smaller icon size */
  }

  .map iframe {
    height: 200px; /* Smaller map on mobile */
    width: 200px;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 1.5rem; /* Further smaller heading */
  }

  .contact-info {
    margin: 20px 0; /* Reduced margin */
  }

  .contact-form input,
  .contact-form textarea,
  .contact-form button {
    width: 100%; /* Full width on very small screens */
  }
}

.success-message{
  color: green !important;
}